@import '~antd/dist/antd.css';

$primary: #457b9d;
$primary-light: #0097d6;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-size: 14px;
	background: #f2f6ff;
}

.ant-btn-primary {
	background: $primary;
	border-color: $primary;

	&:hover, &:focus {
		background: #5c94b7;
		border-color: #5c94b7;
	}
}

.d-flex {
	display: flex;
}

.text-muted {
	color: #B5B5C3;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .footer__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.footer .footer__meta .footer__title {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.footer .footer__meta .footer__build {
  font-size: 12px;
}

.footer .footer__meta .footer__logo {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.footer .menu-item .menu-link {
  color: #B5B5C3;
}

.footer .menu-item .menu-link:hover, .ant-layout .ant-layout-footer .footer .menu-item .menu-link:focus {
  color: #0097d6;
}

.appTour {
	font-size: 16px;
	max-width: 496.5px !important;
	min-width: 225px !important;
}

.table-fixed-to-auto table {
  table-layout: auto !important;
}

.ant-table-cell-ellipsis {
  white-space: normal;
}